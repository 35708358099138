


















































import Vue from 'vue';
import AdminPlayroomService from '@/services/admin/AdminPlayroomService';
import { required, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ToastMessage from '@/models/ToastMessage';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import { MetaInfo } from 'vue-meta';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

class PlayroomEditViewModel {
  activeByAdmin!: boolean;
  id!: string;
  name!: string;
  provisionPercentage!: number;
  forceShowWebsiteLink!: boolean;
  lastHourlyPriceChange!: string;
  lastOvernightPriceChange!: string;
  regularTaxRate!: string;
  reducedTaxRate!: string;
}

const validations = {
  playroom: {
    activeByAdmin: {},
    name: {
      required,
      minLength: minLength(5),
    },
    provisionPercentage: {},
    forceShowWebsiteLink: {},
    regularTaxRate: {},
    reducedTaxRate: {},
  },
};

export default Vue.extend({
  mixins: [validationMixin, FormaterMixin],
  validations: validations,
  components: {
    ValidatedInput,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminPlayroomDetail.meta.title', { playroomName: this.playroom.name }).toString(),
    };
  },
  props: {
    playroomId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      playroom: new PlayroomEditViewModel(),
      errorMessage: '',
    };
  },
  methods: {
    save(): void {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        AdminPlayroomService.save(this.playroom, this.playroomId).then(
          () => {
            this.$store.commit('addToastMessage', new ToastMessage('Speichern erfolgreich', 'bg-success'));
            this.errorMessage = '';
            this.$router.push({ name: 'AdminPlayroomIndex' });
          },
          (error) => {
            this.$store.commit('addToastMessage', new ToastMessage('Speichern fehlgeschlagen', 'bg-warning'));
            this.errorMessage = error;
          }
        );
      }
    },

    setBreadCrumb(): void {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'AdminPlayroomIndex' }, translationKey: 'pages.admin.AdminPlayroomDetail.breadcrumb.playrooms' },
        { text: this.playroom.name },
        { translationKey: 'global.change' },
      ]);
    },

    resetLastPriceChange(): void {
      AdminPlayroomService.resetLastPriceChange(this.playroomId).then(
        () => {
          this.$store.commit('addToastMessage', new ToastMessage('Reset erfolgreich', 'bg-success'));
          this.errorMessage = '';
          this.loadData();
        },
        (error) => {
          this.$store.commit('addToastMessage', new ToastMessage('Reset fehlgeschlagen', 'bg-warning'));
          this.errorMessage = error;
        }
      );
    },
    loadData(): void {
      AdminPlayroomService.getForEdit(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditViewModel;
        this.playroom.forceShowWebsiteLink = res.value.forceShowWebsiteLink == 'True';
        this.setBreadCrumb();
      });
    },
  },
  mounted() {
    this.loadData();
  },
});
